import { Container, spacing } from '@pelotoncycle/design-system';
import styled from 'styled-components';

const ContainerWithLastChildOverRide = styled(Container)`
  ${() => `
      [data-theme=white]:last-of-type > * {        
        padding-block-end: ${spacing[48]};
      }
  `}
`;

export default ContainerWithLastChildOverRide;
