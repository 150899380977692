import type { Entry } from 'contentful';
import React from 'react';
import { ComponentContentTypes } from '@page-builder/lib/constants';
import type { TypeComponentLeadGen, TypeVariation } from '@page-builder/lib/types';
import { toLeadGenModalOrVariation } from '@page-builder/utils/unions';
import Variation from '../Variation/NextVariation';
import LeadGenModal from './LeadGenModal';

type Props = TypeComponentLeadGen | TypeVariation;

const LEAD_GEN_MODAL_ID = ComponentContentTypes.LeadGenModal;

export const overrideContentTypeId = (entry: Entry<any>, overrideId: string) => {
  entry.sys.contentType.sys.id = overrideId;
};

const LeadGenModalOrVariation: React.FC<React.PropsWithChildren<Props>> = data => {
  const { moduleData, variation } = (data && toLeadGenModalOrVariation(data)) || {};

  // Since LeadGenModal doesn't have its own Contentful content model, we need override the sys id
  if (variation) {
    const { control, variations } = variation.fields;
    if (control) {
      overrideContentTypeId(control, LEAD_GEN_MODAL_ID); // Convert sys id ComponentLeadGen -> ComponentLeadGenModal
    }
    if (variations) {
      variations.forEach(variationEntry => {
        overrideContentTypeId(variationEntry, LEAD_GEN_MODAL_ID);
      });
    }

    return <Variation {...variation} />;
  }

  if (!moduleData) return null;

  return <LeadGenModal {...moduleData} />;
};

export default LeadGenModalOrVariation;
