import { ResponsiveImage, spacing } from '@pelotoncycle/design-system';
import { LeadGen } from '@pelotoncycle/page-builder';
import React, { useEffect, useState } from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { toCloudinarySrc } from '@peloton/images/toCloudinarySrc';
import { useLocale } from '@peloton/next/hooks/useLocale';
import type { BlockProps } from '@page-builder/lib/pages';
import type { TypeComponentLeadGen } from '@page-builder/lib/types';
import {
  getContentfulAssetDescription,
  getContentfulAssetUrl,
} from '@page-builder/utils/helpers/cms';
import { nameToModuleId } from '@page-builder/utils/helpers/regex';
import NextModal from '../NextModal';

type LeadGenModalProps = TypeComponentLeadGen & BlockProps;

const LeadGenModal: React.FC<React.PropsWithChildren<LeadGenModalProps>> = ({
  ...props
}) => {
  const [stateIsOpen, setIsOpen] = useState(false);
  const { image } = props.fields;
  const mobile = image?.fields.mobile;
  const moduleId = nameToModuleId(props.fields.name);
  const locale = useLocale();
  const metadata = props.fields.metadata.fields;
  const { trackEvent } = useTracking();

  useEffect(() => {
    if (!Boolean(localStorage.getItem(`${moduleId}.closed`))) {
      setTimeout(() => {
        setIsOpen(true);
      }, 12000);
    }
  }, [setIsOpen, moduleId]);

  const handleOnClose = () => {
    localStorage.setItem(`${moduleId}.closed`, 'true');
    setIsOpen(false);
  };

  return (
    <NextModal
      isOpen={stateIsOpen}
      role="dialog"
      contentLabel="email-capture-modal"
      className="email-capture-modal"
      openHandler={() =>
        trackEvent({
          event: 'Lead Capture Modal Opened',
          properties: {
            campaignId: metadata.campaignId,
            campaignName: metadata.campaignName,
            country: locale,
            productInterest: metadata.productInterest,
            propertyType: 'Web',
            unitName: metadata.unitName,
            unitPlacement: metadata.unitPlacement,
          },
        })
      }
      closeHandler={() => handleOnClose()}
      closeTimeoutMS={400}
      darkMode={true}
      style={{ overflow: 'hidden', borderRadius: '5px', margin: '50px' }}
    >
      {image && (
        <StyledResponsiveImage
          mobile={toCloudinarySrc({ src: getContentfulAssetUrl(mobile), width: 780 })}
          alt={getContentfulAssetDescription(mobile)}
          loading="lazy"
        />
      )}
      <LeadGen style={{ padding: `${spacing[40]}` }} {...props}></LeadGen>
    </NextModal>
  );
};

const StyledResponsiveImage = styled(ResponsiveImage)`
  img {
    display: block;
    max-width: 780px;
    width: 100%;
  }
`;

export default LeadGenModal;
