import React from 'react';
import { useErrorReporter } from '@peloton/error-reporting';
import type {
  TypeComponentLegalText,
  TypeVariation,
  TypePageEcommPageFields,
} from '@page-builder/lib/types';
import Headband from '@page-builder/modules/Headband';
import LeadGenModalOrVariation from '@page-builder/modules/LeadGenModal/LeadGenModalOrVariation';
import LegalText from '@page-builder/modules/LegalText';
import ContainerWithLastChildOverRide from './ContainerWithLastChildOverride';
import { ErrorBoundary, ErrorMessage } from './error-handling';
import type { PageHandler } from './types';

type Props = PageHandler<TypePageEcommPageFields>;

const NextEcommPage: React.FC<React.PropsWithChildren<Props>> = ({
  headband,
  modal,
  hideModal,
  modules,
  block: Block,
  legalText = [],
}) => {
  const { errorReporter } = useErrorReporter();

  return (
    <>
      {headband && <Headband {...headband} />}
      {!hideModal && modal && <LeadGenModalOrVariation {...modal} />}
      <ContainerWithLastChildOverRide>
        {modules
          ? modules.map((module, i) => (
              <ErrorBoundary
                key={`module=${i}`}
                tags={{ component: 'pb-component', entryId: module?.sys?.id }}
                reportError={errorReporter.reportError}
                renderError={({ error }) => (
                  <ErrorMessage error={error} module={module} />
                )}
              >
                <Block index={i} key={`module=${i}`} block={module} />
              </ErrorBoundary>
            ))
          : null}
      </ContainerWithLastChildOverRide>
      {Boolean(legalText.length) && (
        <LegalText legalText={legalText as (TypeComponentLegalText | TypeVariation)[]} />
      )}
    </>
  );
};

export default NextEcommPage;
